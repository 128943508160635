import React from "react";
import logo from "../icons/logo.svg";

const Navbar = () => {
  const scrollTo = (elementId) => {
    document.getElementById(elementId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="w-full px-5 py-4 bg-secondary-50 md:px-6 lg:px-8">
      <div className="flex content-between justify-between w-full max-w-screen-xl mx-auto">
        <img src={logo} alt="logo" />
        <nav className="hidden gap-2 xs:flex">
          <button
            onClick={() => scrollTo('products')}
            className="px-3 py-2 text-sm text-secondary-600 hover:text-secondary-900 hover:bg-secondary-100"
          >
            Products
          </button>
          <button
            onClick={() => scrollTo('contact')}
            className="px-3 py-2 text-sm text-secondary-600 hover:text-secondary-900 hover:bg-secondary-100"
          >
            Contact
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
