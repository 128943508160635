import React from "react";

const SectionHeader = ({ heading, subheading }) => {
  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-3xl font-normal md:text-4xl font-heading">{heading}</h2>
      <p className="text-lg leading-tight md:text-xl text-secondary-500">{subheading}</p>
    </div>
  );
};

export default SectionHeader;
