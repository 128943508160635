import React from "react";
import gif from "../images/hero-animation.gif";

const Hero = () => {
  return (
    <div className="w-full px-5 py-12 border-b md:py-20 bg-secondary-50 md:px-6 lg:px-8 border-secondary-200">
      <div className="flex items-end justify-between max-w-md gap-12 md:mx-auto md:max-w-screen-xl">
        <div className="flex flex-col max-w-2xl gap-1">
          <p className="font-medium uppercase text-secondary-500 md:text-lg">
            Creative Studio
          </p>
          <h1 className="text-3xl font-normal leading-tight md:text-5xl font-heading">
            Crafting Digital Experiences That Inspire
          </h1>
        </div>
        <div className="hidden overflow-hidden lg:block">
          <img src={gif} alt="hero" width={450} style={{transform: "scale(1.01)"}} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
