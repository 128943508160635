import React from "react";

const Footer = () => {
  return (
    <div className="w-full px-5 py-4 border-t bg-secondary-50 md:px-6 lg:px-8 border-neutral-200">
      <div className="flex content-between justify-between w-full max-w-screen-xl mx-auto">
        <p className="text-sm text-neutral-500">© 2024 Modular. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
