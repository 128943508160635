import React from "react";
import Hero from "../components/Hero";
import MainShell from "../components/MainShell";
import SectionHeader from "../components/SectionHeader";
import Button from "../components/Button";
import dashboard from "../images/transport-optimal-dashboard.jpg";
import dashboardStridez from "../images/stridez-dashboard.png";
import ContactUs from "../components/ContactUs";

const Home = () => {
  return (
    <div>
      <Hero />
      <MainShell>
        <div className="flex flex-col gap-12 py-16 md:py-24 md:gap-16" id="products">
          <SectionHeader
            heading="Discover our latest creations"
            subheading="Innovative solutions that define the future"
          />
          <div className="grid items-center grid-cols-1 gap-12 md:gap-16 md:grid-cols-2">
            <div className="">
              <div className="flex flex-col gap-4">
                <h3 className="text-2xl font-normal font-heading md:text-3xl">
                  Stridez - Fitness at Your Fingertips
                </h3>
                <p className="text-lg md:text-xl text-secondary-600">
                  Stridez transforms your fitness goals into reality. With our
                  app, track your progress, get customized workouts, and join a
                  community that motivates you towards optimal health.
                </p>
              </div>
              <a href="https://stridez.io/" target="blank">
                <Button className="mt-8" variant={"secondary"}>
                  Get Fit Now
                </Button>
              </a>
            </div>
            <div className="relative">
              <img
                src={dashboardStridez}
                alt="dashboard"
                className="object-cover w-full h-full border-4 rounded-lg border-secondary-700"
              />
            </div>
          </div>
          <div className="grid items-center grid-cols-1 gap-12 md:gap-16 md:grid-cols-2">
            <div className="relative order-2 md:order-1">
              <img
                src={dashboard}
                alt="dashboard"
                className="object-cover w-full h-full border-4 rounded-lg border-secondary-700"
              />
            </div>
            <div className="order-1 md:order-2">
              <div className="flex flex-col gap-4">
                <h3 className="text-2xl font-normal font-heading md:text-3xl">
                  Move Smarter with Transport Optimal
                </h3>
                <p className="text-lg md:text-xl text-secondary-600">
                  Simplify your move and connect with top movers effortlessly.
                  Transport Optimal offers a seamless platform that tailors
                  every move to your needs. Experience transparency, efficiency,
                  and personalized service.
                </p>
              </div>
              <a href="https://www.transport-optimal.ch/" target="blank">
                <Button className="mt-8" variant={"secondary"}>
                  Explore Now
                </Button>
              </a>
            </div>
          </div>
        </div>
      </MainShell>
      <div className="py-12 md:py-20 bg-secondary-50" id="contact">
        <MainShell>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
            <div className="flex flex-col max-w-2xl gap-1">
              <p className="text-base uppercase text-secondary-500">
                Contact Us
              </p>
              <div className="flex flex-col gap-4">
                <h2 className="max-w-md text-3xl font-normal md:text-4xl font-heading">
                  Have a question? Ask us!
                </h2>
                <p className="text-lg leading-tight md:text-xl text-secondary-500">
                  To ensure your satisfaction, please do not hesitate to reach
                  out to us with any questions or concerns through the provided
                  contact form or directly at:{" "}
                  <a href="mailto:hello@modular-web.com" className="underline">
                    hello@modular-web.com
                  </a>
                </p>
              </div>
            </div>
            <ContactUs />
          </div>
        </MainShell>
      </div>
    </div>
  );
};

export default Home;
